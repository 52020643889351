<template>
  <v-container>
    <div>
      <v-row no-gutters style="margin-bottom: 5px;">
        <h1>Welcome to &nbsp;</h1>
        <h1 class="primary--text">Fluency First</h1>
      </v-row>
      <p>Start from where you left from</p>
      <br />
      <course-organization-component
        :from-student-dashboard="true"
      ></course-organization-component>
    </div>
  </v-container>
</template>

<script>
import CourseOrganizationComponent from "@ecf/modules/course-design/components/CourseOrganization/CourseOrganizationComponent.vue";

export default {
  name: "IeltsDashboard",
  components: { CourseOrganizationComponent },
  computed: {
    username() {
      return this.$store.state.user.user.name;
    }
  }
};
</script>

<style></style>
