var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", [
    _c(
      "div",
      [
        _c(
          "v-row",
          {
            staticStyle: { "margin-bottom": "5px" },
            attrs: { "no-gutters": "" },
          },
          [
            _c("h1", [_vm._v("Welcome to  ")]),
            _c("h1", { staticClass: "primary--text" }, [
              _vm._v("Fluency First"),
            ]),
          ]
        ),
        _c("p", [_vm._v("Start from where you left from")]),
        _c("br"),
        _c("course-organization-component", {
          attrs: { "from-student-dashboard": true },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }